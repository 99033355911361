import ExerciseBookDetailsTwo from '@/components/ExerciseBookDetailsTwo/ExerciseBookDetailsTwo.vue'
export default {
    components: {
        ExerciseBookDetailsTwo
    },
    data() {
        return {}
    },
    mounted() {

    },
    methods: {

    },
}