function realFormatSecond(second) {
  var secondType = typeof second
  if (secondType === 'number' || secondType === 'string') {
    second = parseInt(second)
    var hours = Math.floor(second / 3600)
    second = second - hours * 3600
    var mimute = Math.floor(second / 60)
    second = second - mimute * 60
    return ('0' + mimute).slice(-2) + ':' + ('0' + second).slice(-2)
  } else {
    return '00:00'
  }
}

export default {
  props: ['audioUrl'],
  data() {
    return {
      playing: false,
      playingUrl: require('@/assets/playing.png'),
      pauseUrl: require('@/assets/pause.png'),
      currentTime: 0,
      timeFull: '00:00',
      time: '00:00',
      volume: 100,
      maxTime: 0,
      maxTimeCount: 0,
      url: '',
      reset: true
    }
  },
  mounted() {
    this.init()
    this.audioTime()
  },
  methods: {
    // 获取音频时间
    audioTime() {
      // let audios = document.getElementsByTagName('audio')
      // console.log(document.getElementsByTagName('audio'), '||', this.$refs.audio)
      // let count = setInterval(() => {
      //     if (this.maxTimeCount > 0) {
      //         clearInterval(count)
      //         this.timer()
      //         return
      //     }
      //     if (audios[0].duration != NaN) {
      //         this.maxTimeCount = parseInt(audios[0].duration)
      //     }
      // }, 1000);
    },
    // 计算音频
    timer() {
      let min, sec
      min = sec = 0
      min = parseInt(this.maxTimeCount / 60)
      sec = parseInt(this.maxTimeCount % 60)
      if (this.maxTimeCount < 60) {
        min = '0'
      }
      if (sec < 10) {
        sec = '0' + sec
      }
      if (min < 10) {
        min = '0' + min
      }
      this.timeFull = min + ':' + sec
    },
    init() {
      if (typeof this.audioUrl == 'object') {
        this.url = this.audioUrl.url
      } else {
        this.url = this.audioUrl
      }
    },
    playAudio() {
      let audios = document.getElementsByTagName('audio')
      let btn = document.getElementsByClassName('btn-controls')
      this.$emit('funs', 'ok')
      if (this.playing) {
        this.$refs.audio.pause()
        this.playing = false
      } else {
        // 如果设置了排他性，当前音频播放是，其他音频都要暂停
        ;[...audios].forEach((item) => {
          item.pause()
        })
        ;[...btn].forEach((item) => {
          item.src = require('@/assets/pause.png')
        })
        this.$refs.audio.play()
        this.playing = true
      }
    },
    onLoadedmetadata(res) {
      this.time = '00:00'
      this.maxTime = parseInt(res.target.duration)
      this.timeFull = realFormatSecond(res.target.duration)
    },
    onTimeupdate(res) {
      this.currentTime = parseInt(res.target.currentTime)
      this.time = realFormatSecond(res.target.currentTime)

      if (this.currentTime == this.maxTime) {
        this.playing = false
        // this.currentTime = 0
        // this.time = '00:00'
        // setTimeout(() => {
        //     this.playing = false
        //     this.time = realFormatSecond()
        //     this.currentTime = 0
        // }, 1000);
      }
    },
    changeAudio(value) {
      this.$refs.audio.currentTime = parseInt(value)
    },
    // 控制音量
    changeVolume() {
      this.$refs.audio.volume = this.volume / 100
    }
  },
  watch: {
    audioUrl() {
      this.maxTimeCount = 0
      this.reset = false
      this.$nextTick(() => {
        this.reset = true
        this.init()
        this.audioTime()
      })
    }
  }
}
