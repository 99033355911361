import { apiexericiseList } from '@/api/api.js'
import AudioTool from '@/components/AudioTool/AudioTool.vue'
export default {
    components: {
		AudioTool
    },
    data() {
        return {
            jobId: sessionStorage.getItem('tcl_jobId'),
			item_data: this.$route.query,
            resultData: [],
            page: 1,
            startTime: '',
            endTime: '',
			dialogVisible:false
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        jump(data) {
			sessionStorage.setItem('practice_id', data.id)
			sessionStorage.setItem('caqid',data.caqid)
			this.$router.push({ name: 'SkillExerciseTask' })
        },
        init() {
            this.getJobDetail()
        },
        getJobDetail() {
			console.log(this.item_data)
				let paramsBase = {
				    job_id: this.jobId,
					page:this.page,
				}
				apiexericiseList(paramsBase).then((res) => {
					if(res.data.list.length>0){
						for (let i = 0; i < res.data.list.length; i++) {
							  console.log(res.data.list[i])
							  this.resultData.push(res.data.list[i])
						 }
					}
				    
				})		
        },
        clickSeeDetails() {

        },
        load() {
            this.page++
			this.getJobDetail()
        },
		// getAllasses(){
		// 	this.dialogVisible = true;
		// },
		// handleClose(){
		// 	this.dialogVisible = false;
		// }
    },
}